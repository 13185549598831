import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { ClienteGuard } from "./acompanhamento/guards/cliente.guard";
import { HomeAdminComponent } from "./acompanhamento/modulo-admin/home-admin/home-admin.component";
import { CategoriaGuard } from "./acompanhamento/guards/categoria.guard";
import { LoginClienteComponent } from "./acompanhamento/pages/login-cliente/login-cliente.component";
import { LoginAdminComponent } from "./acompanhamento/pages/login-admin/login-admin.component";
import { PerfilGuard } from "./acompanhamento/guards/perfil.guard";
import { AdminGuard } from "./acompanhamento/guards/admin.guard";

const routes: Routes = [
  {
    path: "",
    component: LoginAdminComponent
  },
  {
    path: "login",
    component: LoginClienteComponent
  },
  {
    path: "login-admin",
    component: LoginAdminComponent
  },
  {
    path: "acompanhamento-cliente",
    loadChildren:"./acompanhamento/modulo-cliente/modulo-cliente.module#ModuloClienteModule",
    canActivate: [ClienteGuard, CategoriaGuard],
  },
  {
    path: "recuperar-senha",
    loadChildren:"./acompanhamento/pages/recuperar-senha/recuperar-senha.module#RecuperarSenhaModule",
  },
  {
    path: "acompanhamento-admin",
    component: HomeAdminComponent,
    canActivate: [AdminGuard, CategoriaGuard],
    
    children: [{
      path: "campanhas",
      loadChildren:"./acompanhamento/pages/campanha-list/campanha-list.module#CampanhaListModule",
      canActivate: [PerfilGuard]
    },{
      path: "revisao-midias",
      loadChildren:"./acompanhamento/pages/revisao-midias/revisao-midias.module#RevisaoMidiasModule"
    },{
      path: "detalhes-campanha-instagram",
      loadChildren: "./acompanhamento/pages/campanha-instagram/campanha-instagram.module#CampanhaInstagramModule"
    },{
      path: "detalhes-campanha-youtube",
      loadChildren: "./acompanhamento/pages/campanha-youtube/campanha-youtube.module#CampanhaYoutubeModule"
    },
    {
      path: "detalhes-campanha-tiktok",
      loadChildren: "./acompanhamento/pages/campanha-tiktok/campanha-tiktok.module#CampanhaTiktokModule"
    },
    {
      path: "detalhes-campanha-twitch",
      loadChildren: "./acompanhamento/pages/campanha-twitch/campanha-twitch.module#CampanhaTwitchModule"
    },{
      path:"cadastro-usuario",
      loadChildren:"./acompanhamento/modulo-admin/user-m4-list/user-m4-list.module#UserM4ListModule",
      canActivate: [PerfilGuard]
    },{
      path:"cadastro-cliente",
      loadChildren:"./acompanhamento/modulo-admin/cliente-list/cliente-list.module#ClienteListModule",
      canActivate: [PerfilGuard]
    },{
      path:"cadastro-usuario-cliente",
      loadChildren:"./acompanhamento/modulo-admin/user-client-list/user-client-list.module#UserClientListModule",
      canActivate: [PerfilGuard]
    },{
      path:"cadastro-smk",
      loadChildren:"./acompanhamento/modulo-admin/user-smk-list/user-smk-list.module#UserSmkListModule",
      canActivate: [PerfilGuard]
    },{
      path:"dashboard-admin",
      loadChildren:"./acompanhamento/modulo-admin/dashboard-admin/dashboard-admin.module#DashboardAdminModule",
      canActivate: [PerfilGuard]
    },{
      path: "busca-influenciadores",
      loadChildren:"./acompanhamento/pages/busca-influenciadores/busca-influenciadores.module#BuscaInfluenciadoresModule",
      canActivate: [PerfilGuard],
    },{
      path: "campanha-scale",
      loadChildren:"./acompanhamento/modulo-admin/campanha-scale-list/campanha-scale-list.module#CampanhaScaleListModule",
      canActivate: [PerfilGuard],
    }
    ,{
      path: "favorabilidade",
      loadChildren:"./acompanhamento/modulo-admin/favorabilidade/favorabilidade.module#FavorabilidadeModule",
      canActivate: [PerfilGuard],
    },{
      path: "comunidade-campanhas",
      loadChildren:"./acompanhamento/pages/comunidade-campanhas/comunidade-campanhas.module#ComunidadeCampanhasModule",
    } 
    ,{
      path: "open-ai",
      loadChildren:"./acompanhamento/pages/open-ai/open-ai.module#OpenAiModule",
      canActivate: [PerfilGuard],
    }]
  },{
    path: "scale",
    loadChildren:"./acompanhamento/modulo-scale/modulo-scale.module#ModuloScaleModule",
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
