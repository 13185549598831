import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicModalStorageService {
  component: any;
  config: any;
  constructor() { }
}
