import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { M4Service } from 'src/app/acompanhamento/services/m4.service';
import { environment } from 'src/environments/environment';
import { ComunidadeCampanhasRedeSocial } from '../models/comunidade-campanhas-rede-social';
import { ComunidadePontuacaoInfluenciador } from '../models/comunidade-pontuacao-influenciador';

@Injectable({
  providedIn: 'root'
})
export class ComunidadeService  extends M4Service{

  constructor(httpCliente: HttpClient) { 
    super(httpCliente);
  }

  salvarComunidade(comunidade: ComunidadeCampanhasRedeSocial): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.salvarComunidade}`;
    return this.post(requestUrl, comunidade);
  }

  listarComunidadeAtiva(page: number = 0,size: number = 10): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.listarComunidadeAtiva}?page=${page}&size=${size}`;
    return this.get(requestUrl);
  }
  getComunidadeById(comunidadeId:string): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.getComunidadeById}?comunidadeId=${comunidadeId}`;
    return this.get(requestUrl);
  }
  getResultadoGamificacaoComunidade(comunidadeId: string): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.getResultadoGamificacoes}?comunidadeId=${comunidadeId}`;
    return this.get(requestUrl);
  }

  salvarMetropoleCoins(pontuacao: ComunidadePontuacaoInfluenciador): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.salvarMetropoleCoins}`;
    return this.post(requestUrl, pontuacao);
  }

  deleteMetropoleCoins(metropoleCoinsId: string): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.deleteMetropoleCoins}?metropoleCoinsId=${metropoleCoinsId}`;
    return this.delete(requestUrl);
  }
  
  salvarGamificacaoPontuacao(pontuacao: ComunidadePontuacaoInfluenciador): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.salvarGamificacaoPontuacao}`;
    return this.post(requestUrl, pontuacao);
  }

  getDetalhesCoinsInfluenciador(comunidadeId: string, influenciadorId:string): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.getDetalhesMetropoleCoinsInfluenciador}?comunidadeId=${comunidadeId}&influenciadorId=${influenciadorId}`;
    return this.get(requestUrl);
  }

  deleteCampanhaComunidade(comunidadeId: string,acompanhamentoId): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.deleteCampanhaComunidade}?comunidadeId=${comunidadeId}&acompanhamentoId=${acompanhamentoId}`;
    return this.delete(requestUrl);
  }
  
  getReultadoInfluenciadorComunidade(influenciadorDataModelId: any, comunidadeId: string) {
    const requestUrl: string = `${environment.serverUrl}/${environment.comunidade.resultadoInfluenciadorComunidade}?comunidadeId=${comunidadeId}&influenciadorDataModelId=${influenciadorDataModelId}`;
    return this.get(requestUrl);
  }
}
