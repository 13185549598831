import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioModel } from '../model/usuario-model';
import { AutenticacaoService } from './autenticacao.service';
import { NavigatorService } from './navigator.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(private auth: AutenticacaoService, private navigatorService: NavigatorService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.spinner.show();
    const usuario: UsuarioModel = this.auth.usuarioData;
    const Authorization: string = usuario && usuario.token ? usuario.token : '';

    if(this.httpCallNeedsToken(req)) {
      req = req.clone({
        setHeaders: {
          'Authorization': Authorization
        } 
      });
    }
    
    // if(req.url.includes('download')) {
    //   req = req.clone({
    //     setHeaders: {
    //       'Accept': 'image/jpeg'
    //     } 
    //   });
    // }

    return next.handle(req).pipe(
      tap(
        event => {
          
          if (event instanceof HttpResponse) {
            // this.spinner.hide();
            this.mostrarMensagensSucesso(event.body);
          }
        },
        error => {
          // this.spinner.hide();
          if (error.status == 401) {
            //alert('Por favor, faça seu login novamente!');
            this.auth.logout();
            this.navigatorService.navegarLogin();
            return;
          }
          this.mostrarMensagensErro(error.error);
        }
      ),
      finalize(() => {
        // this.spinner.hide();
      })
    );
  }

  mostrarMensagensSucesso(response: any): void {
    if (response && response.message) {
      // this.messageService.open(response.message, 'x', {
      //   duration: 2000,
      //   horizontalPosition: 'right',
      //   verticalPosition: 'top',
      //   panelClass: ['custom-snack-bar']
      // });
    }
  }

  mostrarMensagensErro(response: any): void {
    if (response && response.message) {
      // this.messageService.open(response.message, 'x', {
      //   duration: 2000,
      //   horizontalPosition: 'right',
      //   verticalPosition: 'top',
      //   panelClass: ['custom-snack-bar']
      // });
    }
  }

  httpCallNeedsToken(req: HttpRequest<any>): boolean {

    const urls: string[] = [
      environment.loginCliente,
      environment.loginM4
    ];
    
    const resultado: string = urls.find((url: string) => req.url.includes(url) );
    return resultado && resultado.trim().length ? false : true;
  }
}
