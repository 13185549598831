import { Injectable } from '@angular/core';
import { CategoriaModel } from '../model/categoria-model';

@Injectable({
  providedIn: 'root'
})
export class CategoriasStorageService {

  private _categorias: CategoriaModel[];
  
  constructor() { }
  
  get categorias() {
    return this._categorias;
  }
  set categorias(categorias: CategoriaModel[]) {
    this._categorias = categorias;
  }
}