import { Component } from "@angular/core";
import { LoadingService } from "./acompanhamento/services/loading.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor(public loading: LoadingService){

  }
}
