import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigatorService } from '../services/navigator.service';
import { PerfilService } from '../services/perfil.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilGuard implements CanActivate {
  constructor(private perfilService: PerfilService, private navitorService: NavigatorService) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.perfilService.verificarPermissaoUsuarioRota(route)) {
        return true
      }
      this.navitorService.navegarTelaInicialAdm();
   return false;
  }
}