import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Modules } from "src/app/acompanhamento/enums/modules-enum";
import { AutenticacaoService } from "src/app/acompanhamento/services/autenticacao.service";
import { LoginAdminService } from "src/app/acompanhamento/services/login-admin.service";
import { MessageNotificationService } from "../../services/message-notification.service";
import { NavigatorService } from "../../services/navigator.service";
import { RecuperarSenhaStorageService } from '../recuperar-senha/service/recuperar-senha-storage.service';

@Component({
  selector: "app-login-admin",
  templateUrl: "login-admin.component.html"
})
export class LoginAdminComponent implements OnInit, OnDestroy {
  focus;
  focus1;

  constructor(private router: Router, private loginAdminService: LoginAdminService, private autenticacaoService: AutenticacaoService,
    public messageNotificationService: MessageNotificationService, private navigatorService: NavigatorService,
    private recuperarSenhaStorageService:RecuperarSenhaStorageService
    ) {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
    body.classList.remove("white-content");

  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
  }
  entrar(): void {
    let email: string = (<HTMLInputElement>document.getElementById('email')).value;
    const senha: string = (<HTMLInputElement>document.getElementById('pass')).value;

    if(email) {
      email = email.trim();
    }
    this.loginAdminService.post({email, senha}).subscribe(
      res => {
        this.autenticacaoService.setModule(Modules.Admin);
        this.autenticacaoService.updateUser(res);
        this.messageNotificationService.showNotification('Login realizado com sucesso');

        this.navigatorService.navegarTelaInicialAdm();        
      }, 
      err => {
        this.messageNotificationService.showNotification( err.error.descricaoErro);
      }
    );
  }
  abrirModalRecuperarSenha(){
    this.router.navigate(['recuperar-senha'])
    this.recuperarSenhaStorageService.isAdmin = true;

  }
}
