import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageNotificationService {

  constructor(
    public toastr: ToastrService
  ) { }

  showNotification( text: string, from: string = 'top', align: string = 'right'): void {
    this.toastr.info(
      `<span class=" tim-icons icon-bell-55"></span> ${text}`,
      "",
      {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-info alert-with-icon",
        positionClass: "toast-" + from + "-" + align
      }
    );
  }
}
