import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { M4Service } from './m4.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService  extends M4Service {

  constructor(httpCliente: HttpClient) { 
    super(httpCliente);
  }

  getInstagramData() {
    const requestUrl: string = `${environment.serverUrl}/${environment.dashboard.getInstagramDataDashboard}`;
    return this.get(requestUrl);
  }

  getChartUsuariosAtivos(year?, month?) {
    let params = {};
    
    if(month != undefined)
      params = {refYear: year,refMonth: month}
    else
      params = {refYear: year}

    const requestUrl: string = `${environment.serverUrl}/${environment.dashboard.getChartDataUsuariosAtivos}`;
    return this.get(requestUrl, params);
  }

  completions(openAiCompletionDTO: {text: string}) {
    const requestUrl: string = `${environment.serverUrl}/${environment.openAI.completions}`;
    return this.post(requestUrl, openAiCompletionDTO);
  }

  createImages(openAiImageDTO: {prompt: string, n: number}) {
    const requestUrl: string = `${environment.serverUrl}/${environment.openAI.createImages}`;
    return this.post(requestUrl, openAiImageDTO);
  }
}