import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  PercentPipe,
  registerLocaleData,
} from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { PaginationModule, PopoverModule } from "ngx-bootstrap";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ptBrLocale } from "ngx-bootstrap/locale";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TagInputModule } from "ngx-chips";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { NgxMaskModule } from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { DynamicModalComponent } from "./acompanhamento/components/dynamic-modal/dynamic-modal.component";
import { HomeAdminComponent } from "./acompanhamento/modulo-admin/home-admin/home-admin.component";
import { HomeComponent } from "./acompanhamento/pages/home/home.component";
import { LoginAdminComponent } from "./acompanhamento/pages/login-admin/login-admin.component";
import { LoginClienteComponent } from "./acompanhamento/pages/login-cliente/login-cliente.component";
import { HttpInterceptorService } from "./acompanhamento/services/http-interceptor.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
defineLocale("pt-br", ptBrLocale);

registerLocaleData(localePt);
FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    LoginClienteComponent,
    LoginAdminComponent,
    HomeAdminComponent,
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RtlLayoutComponent,
    HomeComponent,
    DynamicModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    TagInputModule,
    BsDatepickerModule.forRoot(),
    ModalModule,
    TypeaheadModule.forRoot(),
    NgxMaskModule.forRoot({ dropSpecialCharacters: true }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: "rgba(0,0,0,0.8)",
      backdropBorderRadius: "4px",
      primaryColour: "#e14eca",
      secondaryColour: "#e14eca",
      tertiaryColour: "#e14eca",
    }),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BrowserModule,
    FullCalendarModule,
    PaginationModule.forRoot(),
  ],
  providers: [
    BsModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    DatePipe,
    PercentPipe,
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "BRL" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
