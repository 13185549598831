import { Injectable } from '@angular/core';
import { BsModalService} from 'ngx-bootstrap/modal';
import { DynamicModalComponent } from '../components/dynamic-modal/dynamic-modal.component';
import { DynamicModalStorageService } from './dynamic-modal-storage.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicModalService {
 
  subscriptions: Subscription[] = [];
  constructor(private modalService: BsModalService, private dynamicModalStorageService: DynamicModalStorageService, ) { }

  showModal(component: any, initialState: any) {
    const options: any = Object.assign({}, initialState, { backdrop: true, ignoreBackdropClick: true});
    
    this.dynamicModalStorageService.component = component;
    this.dynamicModalStorageService.config = initialState;
    return this.modalService.show(DynamicModalComponent, options);
  }
}