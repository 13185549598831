import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { M4Service } from './m4.service';

@Injectable({
  providedIn: 'root'
})
export class LoginAdminService extends M4Service {

  constructor(httpCliente: HttpClient) { 
    super(httpCliente);
  }

  post(data: any): Observable<any> {
    // console.log('POST');
    // console.log(JSON.stringify(data, null, 2));
    // console.log('----------------------------------------');
    const requestUrl: string = `${environment.loginUrl}/${environment.loginM4}`;
    return super.post(requestUrl, data);
  }
}
