import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";

import { PerfilUserClientFormComponent } from './perfil-user-client-form.component';


@NgModule({
  declarations: [PerfilUserClientFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    AngularMultiSelectModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [PerfilUserClientFormComponent]
})
export class PerfilUserClientFormModule { }
