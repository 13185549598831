// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // loginUrl: 'http://localhost:8082',
  // serverUrl: 'http://localhost:8082',
  // openApiServerUrl: 'http://localhost:8087',
  // loginUrl: 'https://metropole-api.metropole4.com',
  // serverUrl: 'https://metropole-api.metropole4.com',
  // openApiServerUrl: 'https://open-api.metropole4.com',
  loginUrl: 'https://metropole-api-hml.metropole4.com',
  serverUrl: 'https://metropole-api-hml.metropole4.com',
  openApiServerUrl: 'https://open-api-hml.metropole4.com',

  instagram: 'https://www.instagram.com',

  loginM4: 'auth-api/sso/login-m4',
  loginCliente: 'auth-api/sso/login-user-cliente',
  gravarUsuarioM4:'auth-api/sso/grava-usuario-m4',
  listarUsuariosM4: 'auth-api/sso/listar-usuarios-m4',

  recuperarCodigoUsuariosM4: 'auth-api/sso/recuperar-codigo-admin',
  recuperarSenhaUsuariosM4: 'auth-api/sso/recuperar-senha-admin',
  recuperarCodigoCliente: 'auth-api/sso/recuperar-codigo',
  recuperarSenhaCliente: 'auth-api/sso/recuperar-senha',

  gravaCliente: 'campanha-api/sso/grava-cliente',
  listaCliente: 'campanha-api/sso/listar-cliente',
  listarSmksCriadosManualmente: 'campanha-api/sso/get-smks-manuais',
  getUsuariosM4: 'campanha-api/sso/get-lista-usuarios-m4',
  getClientes: 'campanha-api/sso/get-lista-usuarios-acompanhamento',
  getUsuariosClienteByEmail: 'campanha-api/sso/get-lista-usuarios-cliente',
  getUsuariosClienteById: 'campanha-api/sso/get-lista-usuarios-by-cliente-id',
  gravaUsuarioCliente: 'campanha-api/sso/grava-usuario-cliente',
  listaUsuarioCliente: 'campanha-api/sso/listar-todos-usuarios-cliente',
  
  gravaUserAndFacebookUserData: 'campanha-api/facebook-user/grava-user-facebook-data',

  atualizarUsuarioCliente: 'campanha-api/usuario-cliente/atualizar-usuario-cliente',
  atualizarSenhaUsuarioCliente: 'campanha-api/usuario-cliente/atualizar-senha-usuario-cliente',
  atualizarUsuarioM4: 'campanha-api/usuario-m4/atualizar-usuario-m4',
  atualizarSenhaUsuarioM4: 'campanha-api/usuario-m4/atualizar-senha-usuario-m4',
  
  postCampanha: 'campanha-api/campanha/salvar-campanha',
  getCampanhaById: 'campanha-api/campanha/get-campanha-by-id',


  
  getResultadoGamificacaoCampanha: 'campanha-api/gamificacao/get-resultado-gamificacao',
  getGamificacaoPontuacao:'campanha-api/gamificacao-pontuacao/get-pontuacao-influenciador-categoria',
  excluirInfluenciadorCampanha: 'campanha-api/campanha/excluir-influenciador-campanha',
  filterCampanhasAtivas:'campanha-api/campanha/filter-campanha-ativa-by-name',
  getInfluenciadoresConvidados:'campanha-api/campanha/get-influenciadores-convidados',
  enviarConvitesInfluenciadoresCampanha:'campanha-api/campanha/convidar-influenciador-campanha',
  
  //calendar
  salvarEventoCalendarioCampanha: 'campanha-api/evento-calendario/salvar-evento-calendario',
  listarEventosCalendarioCampanha: 'campanha-api/evento-calendario/listar-eventos-calendario',
  excluirEventosCalendarioCampanha: 'campanha-api/evento-calendario/excluir-evento-calendario',

  sincronizarPostagens: 'campanha-api/facebook-ig-media/sincronizar',
  listarPostagens: 'campanha-api/facebook-ig-media/listar',
  removerIgMedia: 'campanha-api/facebook-ig-media/remover',
  sincronizarPostagensInfluenciador: 'campanha-api/facebook-ig-media/sincronizar-influenciador',
  pausarSincronizacaoInfluenciador: 'campanha-api/facebook-ig-media/pausar-sincronizacao-influenciador',
  getInfluencer: 'campanha-api/facebook-influencer/get-influenciador',
  getInfluencerById: 'campanha-api/facebook-influencer/get-influenciador-by-id',
  listaUsuarioFacebookUserData: 'campanha-api/facebook-influencer/get-listar-facebook-user-data',

  getCategorias: 'campanha-api/categoria/get-lista-todas-categorias',
  getRelatorioInfluCamp: 'campanha-api/facebook-influencer/resultado-influenciador-campanha',
  getPostsInfluencer: 'campanha-api/facebook-influencer/recuperar-posts-influencer',
  getRelatorioCampanha: 'campanha-api/campanha/get-relatorio-campanha',
  consolidarResultados: 'campanha-api/campanha/consolidar-resultados',
  reconsolidarResultados: 'campanha-api/campanha/reconsolidar-resultados',

  filtrarHistorias: 'campanha-api/historia/pesquisar',
  atualizarFacebookIgMedia: 'campanha-api/facebook-ig-media/atualizar-historia',
  facebookIgMediaImageDownload: 'campanha-api/facebook-ig-media/download-midia-image',
  gerarVideoThumbnail: 'campanha-api/aws/gerar-thumbnail-video',

  getInfluenciadoresByUsername: 'campanha-api/facebook-influencer/filtrar-influenciadores-by-username',
  
  filterSmksByName: 'campanha-api/smk/filtrar-by-username',

  cadastrarMidia: 'campanha-api/facebook-ig-media/cadastrar-facebook-midia',

  filterAudienceCity: 'campanha-api/audience-city/search-by-name',
  
  filterMunicipio: 'campanha-api/municipio/filter-by-name',

  listarCampanhaScale: 'campanha-api/scale/listar',
  salvarCampanhaScale: 'campanha-api/scale/salvar',
  listarComentarios: 'campanha-api/facebook-ig-media/listar-comentarios-by-id',
  removerFacebookIgMediaComentarios: 'campanha-api/facebookigmedia-comments/remover-comentarios',
  revisaoMidias:{
    getTodasMidiasRevisao:'campanha-api/revisao-midias/get-revisao-midias',
    getCampanhasFilter:'campanha-api/campanha-rede-social/filtro-campanhas-by-name'

  },
  relatorioFinalV2:{
    migrarRelatorioNovaVersao:'campanha-api/campanha/set-versao-relatorio-antigo',
    getRelatorioCampanhaV2: 'campanha-api/campanha/get-relatorio-campanha-v2',
    consolidarResultadosV2: 'campanha-api/campanha/consolidar-resultados-v2',
    desconsolidarResultadosV2: 'campanha-api/campanha/desconsolidar-resultados-v2',
  },

  dashboard: {
    getInstagramDataDashboard: 'campanha-api/dashboard/get-instagram-data',
    getChartDataUsuariosAtivos: 'campanha-api/dashboard/usuarios-ativos-chart'
  },

  campanhaRedeSocial: {
    getCampanhasAtivas: 'campanha-api/campanha-rede-social/get-lista-todas-campanhas-ativas',
    getCampanhasEncerradas: 'campanha-api/campanha-rede-social/get-lista-todas-campanhas-encerradas',
    getCampanhasAtivasCliente: 'campanha-api/campanha-rede-social/get-lista-todas-campanhas-ativas-user-cliente',
    getCampanhasEncerradasCliente: 'campanha-api/campanha-rede-social/get-lista-todas-campanhas-encerradas-user-cliente',
    migrarCampanhasAntigas: 'campanha-api/campanha-rede-social/migrar-campanhas-antigas',
    salvarCampanha: 'campanha-api/campanha-rede-social/salvar',
    getAcompanhamentoCampanhaId: 'campanha-api/campanha-rede-social/get-by-acompanhamento-id',
    filterCampanhasEncerradas:'campanha-api/campanha-rede-social/filter-campanha-encerrada-by-name',
    filterCampanhasAtivasParams:'campanha-api/campanha-rede-social/filter-campanha-ativa-comunidade',
    filterCampanhasAtivas:'campanha-api/campanha-rede-social/filter-campanha-ativa-by-name',
  },
  midias: {
    pendingValidation: (campanhaId: string) => `campanha-api/facebook-ig-media/listar-pendentes-validacao?campanhaId=${campanhaId}`,
    validarMidia: (campanhaId: string, mediaId: string, isApproved: boolean) => 
      `campanha-api/facebook-ig-media/validar-midia/${mediaId}?campanhaId=${campanhaId}&aprovado=${isApproved}`,
  },
  youtube: {
    salvarAcompanhamentoCampanha: 'youtube-api/acompanhamento-campanha/salvar',
    getYoutubeAcompanhamentoCampanhaByCampanhaId: 'youtube-api/acompanhamento-campanha/get-by-campanha-id',
    getPostagensAcompanhamentoCampanhaByCampanhaId: 'youtube-api/acompanhamento-campanha/get-postagens-campanha',
    filtrarSmkPorNome: 'youtube-api/smk/filtrar-por-nome',
    perfilPublicoYoutube: 'perfil-publico-youtube',
    sincronizarPostagens: 'youtube-api/acompanhamento-campanha/sincronizar-postagens-influenciador',
    pausarSincronizacaoInfluenciador: 'youtube-api/acompanhamento-campanha/pausar-sincronizacao-influenciador',
    recuperarMidiasInfluenciadorAcompanhamento: 'youtube-api/youtube-media/recuperar-midias-influenciador-acompanhamento',
    recuperarRelatorioInfluenciadorAcompanhamento: 'youtube-api/acompanhamento-campanha/relatorio-influenciador-acompanhamento',
    recuperarRelatorioAcompanhamento: 'youtube-api/acompanhamento-campanha/relatorio-acompanhamento',
    consolidarResultados: 'youtube-api/acompanhamento-campanha/consolidar-resultados',
    youtubeUrl: 'https://www.youtube.com',
  },
  tiktok: {
    salvarAcompanhamentoCampanha: 'tiktok-api/acompanhamento-campanha/salvar',
    getTiktokAcompanhamentoCampanhaByCampanhaId: 'tiktok-api/acompanhamento-campanha/get-by-campanha-id',
    getPostagensAcompanhamentoCampanhaByCampanhaId: 'tiktok-api/acompanhamento-campanha/get-postagens-campanha',
    filtrarSmkPorNome: 'tiktok-api/smk/filtrar-por-nome',
    perfilPublicoTiktok: 'perfil-publico-tiktok',
    sincronizarPostagens: 'tiktok-api/acompanhamento-campanha/sincronizar-postagens-influenciador',
    pausarSincronizacaoInfluenciador: 'tiktok-api/acompanhamento-campanha/pausar-sincronizacao-influenciador',
    recuperarMidiasInfluenciadorAcompanhamento: 'tiktok-api/tiktok-media/recuperar-midias-influenciador-acompanhamento',
    recuperarRelatorioAcompanhamento: 'tiktok-api/acompanhamento-campanha/relatorio-acompanhamento',
    consolidarResultados: 'tiktok-api/acompanhamento-campanha/consolidar-resultados',
    tiktokUrl: 'https://www.tiktok.com',
  },
  twitch:{
    salvarAcompanhamentoCampanha: 'twitch-api/acompanhamento-campanha/salvar',
    getTwitchAcompanhamentoCampanhaByCampanhaId: 'twitch-api/acompanhamento-campanha/get-by-campanha-id',
    getPostagensAcompanhamentoCampanhaByCampanhaId: 'twitch-api/acompanhamento-campanha/get-postagens-campanha',
    filtrarSmkPorNome: 'twitch-api/twitchuserdata/filtrar-por-nome',
    perfilPublicoTwitch: 'perfil-publico-twitch',
    sincronizarPostagens: 'twitch-api/acompanhamento-campanha/sincronizar-postagens-influenciador',
    pausarSincronizacaoInfluenciador: 'twitch-api/acompanhamento-campanha/pausar-sincronizacao-influenciador',
    recuperarMidiasInfluenciadorAcompanhamento: 'twitch-api/twitch-media/recuperar-midias-influenciador-acompanhamento',
    recuperarRelatorioAcompanhamento: 'twitch-api/acompanhamento-campanha/relatorio-acompanhamento',
    consolidarResultados: 'twitch-api/acompanhamento-campanha/consolidar-resultados',
    twitchURL: 'https://www.twitch.tv/',
  },

  
  buscarMunicipio:'campanha-api/sso/encontrar-municipio',

  smk: {
    home:'https://localhost:4200',
    //home: 'https://mediakit-hml.metropole4.com',
    perfilPublico: 'perfil-publico',
    perfilPublicoYt: 'perfil-publico-youtube',
    perfilPublicoTk: 'perfil-publico-tiktok',
    perfilPublicoTWC: 'perfil-publico-twitch',

  },

  cloudFront: {
    base: 'https://d2xpskpbomccy.cloudfront.net',
    smk: 'https://d3qkallkgcp4iu.cloudfront.net',
  },

  storytelling: {
    atualizarTags: 'campanha-api/facebook-ig-media/atualizar-storytelling-tags'
  },
  usuarioM4:{
    deletarUsuario: 'campanha-api/usuario-m4/deletarUsuario'
  },
  filtroInfluenciadorInstagram:{
    filtro:'campanha-api/facebook-influencer/filtrar-influenciadores-by-metrics'
  },
  m4OpenApi: {
    gravarLinkParametrizado: 'link-parametrizado/salvar',
    recuperaLinkParametrizadoByCampanhaId: 'link-parametrizado/by-campanha-id',
    remover: 'link-parametrizado/remover',
    getSerieHistorica: 'link-parametrizado/get-serie-historica',
    smallLink: 'https://hml.m4i.link'
  },
  favorabilidade:{
    listarComentariosFilter:'campanha-api/facebook-ig-media/listar-comentarios-by-maior-predicao',
    atualizarPredicao:'campanha-api/comments/atualizar-predicao',
    getCommentIA:'campanha-api/comments/get-comment-IA'

  },
  users: {
    getById: 'campanha-api/users/get-by-id'
  },
  comunidade:{
    salvarComunidade:'campanha-api/comunidade/salvar-comunidade',
    listarComunidadeAtiva:'campanha-api/comunidade/listar-comunidades-ativas',
    getComunidadeById:'campanha-api/comunidade/get-comunidade-by-id',
    salvarMetropoleCoins:'campanha-api/metropole-coins/salvar-metropole-coins',
    deleteMetropoleCoins:'campanha-api/metropole-coins/excluir-metropole-coins',

    salvarGamificacaoPontuacao:'campanha-api/gamificacao-pontuacao/salvar-pontuacao',

    getDetalhesMetropoleCoinsInfluenciador:'campanha-api/metropole-coins/get-detalhes-coins-influenciador',
    getResultadoGamificacoes:'campanha-api/comunidade/get-resultado-gamificacao-comunidade',
    deleteCampanhaComunidade:'campanha-api/comunidade/remover-campanha-comunidade',
    
    listarComunidadeAtivaUserCliente:'campanha-api/comunidade/listar-comunidades-ativas-user-cliente',
    resultadoInfluenciadorComunidade:'campanha-api/comunidade/resultado-influenciador-comunidade',
  },
  openAI: {
    completions: 'campanha-api/open-ai/completions',
    createImages: 'campanha-api/open-ai/create-images'
  },
  relatorioFinal:{
    criarInformacoesAdicionais:'campanha-api/info-relatorio-campanha/criar',
    getInformacoesRelatorio:'campanha-api/info-relatorio-campanha/get-informacoes-relatorio'

  },
  hypeauditor: {
    discovery: "campanha-api/hp/discovery",
    report: "campanha-api/hp/report",
    taxonomy: "campanha-api/hp/get-taxonomy",
    suggester: "campanha-api/hp/suggester"
  },
  geoname: {
    search: "campanha-api/geoname/search"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
