export class PerfilModel {
    _id: string;
    nome: string;
    codigo: string;
    rotasPermitidas: string[];

    constructor(codigo: string, nome: string, rotasPermitidas: string[]) {
        this.codigo = codigo;
        this.nome = nome;
        this.rotasPermitidas = rotasPermitidas;
    }
}
