import { Perfil } from "../../enums/perfil";
import { RouteInfo } from "../../interfaces/route-info";

//Menu Items
export const ROUTES_ADMIN: RouteInfo[] = [
	// {
	// 	path: "/acompanhamento-admin",
	// 	title: "Dashboard",
	// 	type: "link",
	// 	icontype: "tim-icons icon-chart-pie-36",
	// 	rtlTitle: "لوحة القيادة"
	// }, 
	{
		path: "dashboard-admin",
		title: "Dashboard",
		type: "link",
		icontype: "tim-icons icon-chart-pie-36",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorCampanha]
	},{
		path: "campanhas",
		title: "Minhas Campanhas",
		type: "link",
		icontype: "tim-icons icon-paper",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorCampanha]
	},{
		path: "revisao-midias",
		title: "Revisão de Mídias",
		type: "link",
		icontype: "tim-icons icon-video-66",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorCampanha]
	},{
		path: "cadastro-usuario",
		title: "Cadastrar usuário M4",
		type: "link",
		icontype: "tim-icons icon-single-02",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador]
	}, {
		path: "cadastro-cliente",
		title: "Cadastrar Cliente",
		type: "link",
		icontype: "tim-icons icon-bank",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorAdministrativo]
	},{
		path: "cadastro-usuario-cliente",
		title: "Cadastrar Usuário Cliente",
		type: "link",
		icontype: "tim-icons icon-badge",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador,Perfil.ConsultorCampanha]
	},{
		path: "cadastro-smk",
		title: "Cadastrar SMK Instagram",
		type: "link",
		icontype: "tim-icons icon-badge",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorCampanha]
	},{
		path: "busca-influenciadores",
		title: "Buscar Influenciadores",
		type: "link",
		icontype: "tim-icons icon-zoom-split",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorCampanha]
	},
	{
		path: "campanha-scale",
		title: "Campanha Scale",
		type: "link",
		icontype: "tim-icons icon-paper",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador]
	},
	{
		path: "favorabilidade",
		title: "Favorabilidade",
		type: "link",
		icontype: "tim-icons icon-shape-star",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador]
	},
	{
		path: "comunidade-campanhas",
		title: "Comunidade",
		type: "link",
		icontype: "fas fa-users",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador, Perfil.Coordenador, Perfil.ConsultorCampanha]
	}, {
		path: "open-ai",
		title: "Open Ai",
		type: "link",
		icontype: "tim-icons icon-bulb-63",
		rtlTitle: "لوحة القيادة",
		perfis: [Perfil.Administrador]
	}
	// {
	// 	path: "cadastro-smk",
	// 	title: "Cadastrar Usuário SMK",
	// 	type: "link",
	// 	icontype: "tim-icons icon-chart-pie-36",
	// 	rtlTitle: "لوحة القيادة"
	// }
];