import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class M4Service {

  constructor(private http: HttpClient) { }

  get(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }): Observable <any> {
    return this.http.get<any>(requestUrl, {params:params});
  }
  
  delete(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }): Observable <any> {
    return this.http.delete<any>(requestUrl, {params:params});
  }

  post(requestUrl: string, data: any): Observable<any> {
    // console.log('POST');
    // console.log(JSON.stringify(data, null, 2));
    // console.log('----------------------------------------');
    
    return this.http.post<any>(requestUrl, data);
  }

  put(requestUrl: string, data: any): Observable<any> {
    // console.log('POST');
    // console.log(JSON.stringify(data, null, 2));
    // console.log('----------------------------------------');
    
    return this.http.put<any>(requestUrl, data);
  }

  
  postFormData(requestUrl: string, data: FormData): Observable<any> {
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    headers.set('Content-Type', "application/json");
    headers.set('Accept', "multipart/form-data");

    // console.log('POST');
    // console.log(JSON.stringify(data, null, 2));
    // console.log('----------------------------------------');
    const options = {} as any;

    return this.http.post<any>(requestUrl, data, options);
  }

  getExternalUrl(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }): Observable<any> {
    return new Observable((x) => {
      let req = new XMLHttpRequest();
      req.open('get', requestUrl, true);
      req.send();
      req.onload = function(){
        let data = JSON.parse(req.response);
        x.next(data);
      }
    });
  }

  getImage(requestUrl: string): Observable<any> {
    const httpOption: any = {
      headers: new HttpHeaders({
        'Accept': 'image/jpeg',
      }),
      responseType: 'blob' // This tells angular to parse it as a blob, default is json
    };
    return this.http.get<any>(requestUrl, httpOption);
  }
}
