import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PerfilModel } from '../model/perfil-model';
import { AutenticacaoService } from './autenticacao.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  private _perfis: PerfilModel[];

  private readonly rotasPermitidasAdministrador = [
    "campanhas",
    'detalhes-campanha',
    'cadastro-usuario',
    'cadastro-cliente',
    'cadastro-usuario-cliente',
    'cadastro-smk',
    'dashboard-admin',
    'busca-influenciadores',
    'campanha-scale',
    'favorabilidade',
    'comunidade-campanhas',
    'open-ai'
  ];
  private readonly rotasPermitidasCoordenadores = [
    "campanhas",
    'detalhes-campanha',
    'cadastro-cliente',
    'cadastro-usuario-cliente',
    'cadastro-smk',
    'dashboard-admin',
    'busca-influenciadores',
    'comunidade-campanhas'
  ];
  private readonly rotasPermitidasConsultorAdministrativo = [
    'cadastro-cliente'
  ];
  
  private readonly rotasPermitidasConsultorCampanha = [
    "campanhas",
    'detalhes-campanha',
    'cadastro-usuario-cliente',
    'cadastro-smk',
    'dashboard-admin',
    'busca-influenciadores',
    'comunidade-campanhas'
  ];

  constructor(private autenticacaoService: AutenticacaoService) {
    if(!this._perfis) {
      this._perfis = [
        new PerfilModel("Administrador", "Administrador", this.rotasPermitidasAdministrador),
        new PerfilModel("Coordenador", "Coordenador", this.rotasPermitidasCoordenadores),
        new PerfilModel("ConsultorCampanha", "Consultor de Campanhas", this.rotasPermitidasConsultorCampanha),
        new PerfilModel("ConsultorAdministrativo", "Consultor Administrativo", this.rotasPermitidasConsultorAdministrativo)
      ]
    }
  }

  get perfis(): PerfilModel[] {
    return this._perfis;
  }

  verificarPermissaoUsuarioRota(route: ActivatedRouteSnapshot) {
    const rota: string = route.routeConfig.path;
    const perfil = this._perfis.find( (perfil: PerfilModel) => perfil.codigo == this.autenticacaoService.getUserPerfil());

    if(perfil.rotasPermitidas.includes(rota)){
      return true;
    }

    return false;
  }
}