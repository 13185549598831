import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CampanhaModel } from '../model/campanha-model';
import { M4Service } from './m4.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends M4Service {

  constructor(httpCliente: HttpClient) { 
    super(httpCliente);
  }

  getUsuariosM4(): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.getUsuariosM4}`;
    return this.get(requestUrl);
  }

  getUsuariosClienteByEmail(params?: HttpParams | { [param: string]: string | string[]; }): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.getUsuariosClienteByEmail}`;
    return this.get(requestUrl, params);
  }

  getUsuariosClienteByName(params?: HttpParams | { [param: string]: string | string[]; }): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.getClientes}`;
    return this.get(requestUrl, params);
  }
  
  getInfluencer(params?: HttpParams | { [param: string]: string | string[]; }) {    
    const requestUrl: string = `${environment.serverUrl}/${environment.getInfluencer}`;
    return this.get(requestUrl, params);
  }
  
  getInfluencerById(params?: HttpParams | { [param: string]: string | string[]; }) {    
    const requestUrl: string = `${environment.serverUrl}/${environment.getInfluencerById}`;
    return this.get(requestUrl, params);
  }

  listarUsuariosM4(page: number = 0,size: number = 12): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.listarUsuariosM4}?page=${page}&size=${size}`;
    return this.get(requestUrl);
  }

  postGravarUsuarioM4(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.gravarUsuarioM4}`;
    return this.post(requestUrl, form);
  }

  deletarUsuarioM4(idUsuario: string): Observable<any>{
    const requestUrl: string = `${environment.serverUrl}/${environment.usuarioM4.deletarUsuario}/${idUsuario}`
    return this.delete(requestUrl)
  }
  
  listarCliente(page: number = 0,size: number = 12): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.listaCliente}?page=${page}&size=${size}`;
    return this.get(requestUrl);
  }

  postGravarCliente(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.gravaCliente}`;
    return this.post(requestUrl, form);
  }

  listarUsuariosCliente(page: number = 0,size: number = 12): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.listaUsuarioCliente}?page=${page}&size=${size}`;
    return this.get(requestUrl);
  }

  postGravarUsuarioCliente(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.gravaUsuarioCliente}`;
    return this.post(requestUrl, form);
  }

  postGravarAtualizarUsuarioCliente(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.atualizarUsuarioCliente}`;
    return this.post(requestUrl, form);
  }

  postGravarAtualizarSenhaUsuarioCliente(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.atualizarSenhaUsuarioCliente}`;
    return this.post(requestUrl, form);
  }

  postGravarAtualizarUsuarioM4(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.atualizarUsuarioM4}`;
    return this.post(requestUrl, form);
  }

  postGravarAtualizarSenhaUsuarioM4(form: any): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.atualizarSenhaUsuarioM4}`;
    return this.post(requestUrl, form);
  }

  getUsuariosCliente(params?: HttpParams | { [param: string]: string | string[]; }): Observable<any> {
    const requestUrl: string = `${environment.serverUrl}/${environment.getUsuariosClienteById}`;
    return this.get(requestUrl, params);
  }
}
