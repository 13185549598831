export class UsuarioModel {
    private _token: string;
    private _celular: string;
    private _email: string;
    private _nome: string;
    private _telefone: string;
    private _id: string;
    private _perfil: string;
    private _cliente: any;

    get token(): string {
        return this._token;
    }
    set token(token: string) {
        this._token = token;
    }

    get celular(): string {
        return this._celular;
    }
    set celular(celular: string) {
        this._celular = celular;
    }

    get email(): string {
        return this._email;
    }
    set email(email: string) {
        this._token = email;
    }

    get nome(): string {
        return this._nome;
    }
    set nome(nome: string) {
        this._nome = nome;
    }

    get telefone(): string {
        return this._telefone;
    }
    set telefone(telefone: string) {
        this._telefone = telefone;
    }

    get id(): string {
        return this._id;
    }
    set id(id: string) {
        this._id = id;
    }

    get perfil(): string {
        return this._perfil;
    }
    set perfil(perfil: string) {
        this._perfil = perfil;
    }

    get cliente(): any {
        return this._cliente;
    }
    set cliente(perfil: any) {
        this._cliente = perfil;
    }
}

export class UsuarioM4Model {
    email: string = '';
    telefone: string = '';
    senha: string = '';
    celular: string = '';
    nome: string ='';
    _id: any;
    ativo: boolean;
    perfil: string;
}

export class ClienteModel{
    razaoSocial: string = '';
    nomeFantasia: string = '';
    cnpj: string = '';
    website: string;
    youtube: string;
    twitter: string;
    tiktok: string;
    linkedin: string;
    instagram: string;
    endereco: EnderecoClienteModel = new EnderecoClienteModel();
    contato: ContatosClienteModel = new ContatosClienteModel();
    dadosFinanceiros: DadosFinanceirosModel = new DadosFinanceirosModel();
    _id: any;
}

export class DadosFinanceirosModel{
    inscricaoEstadual: string = '';
    inscricaoMunicipal: string = '';
    mesmoEndereco: boolean = true;
    enderecoFinanceiro: EnderecoClienteModel = new EnderecoClienteModel();
}

export class EnderecoClienteModel {
    pais: string = '';
    cep: string = '';
    rua: string = '';
    numero: string = '';
    complemento: string = '';
    bairro: string = '';
    cidade: string = '';
    estado: string = '';
}

export class ContatosClienteModel {
    nome: string = '';
    email: string = '';
    celular: string = '';
    tipoContato: string = '';
}


export class UsuarioSmkModel {
    nome: string = '';
    email: string = '';
    categorias: any[] = [];

    senha: string;
    telefone: string;
    idEstado: string;
    idCidade: string;
    nascimento: Date;
}

export class UsuarioClienteModel {
    _id: string;
    nome: string = '';
    email: string = '';
    senha: string;
    token: string;
    cliente: ClienteModel
}

export class AtualizarUsuarioM4Model {
    email: string = '';
    telefone: string = '';
    senha: string = '';
    celular: string = '';
    nome: string ='';
    _id: any;
    novaSenha: string = '';
}


export class AtualizarUsuarioClienteModel {
    _id: string;
    nome: string = '';
    email: string = '';
    senha: string;
    novaSenha: string = '';
}