export function convertEnumToArray (enumObj: any) {
    const arr: {name: string, value: string}[] = [];
    for(const item in enumObj) {
        arr.push({
            name: item,
            value: enumObj[item]
        });
    }
    return arr;
}

import { FormGroup, ValidatorFn } from "@angular/forms";

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup): { [key: string]: any } | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}