import { Injectable } from '@angular/core';
import { UsuarioModel } from '../model/usuario-model';
import { Modules } from '../enums/modules-enum';
import { Router } from '@angular/router';
import { Perfil, PerfilCliente } from '../enums/perfil';

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  private readonly acompanhamentoAdmId = "m4#acompanhamentoadmin";
  private readonly acompanhamentoClienteId = "m4#acompanhamentocliente";
  private _currentModuleId: string;

  private _usuarioData: UsuarioModel = new UsuarioModel();

  constructor(
    private router: Router
  ) { }

  get usuarioData(): UsuarioModel {
    if(!this._usuarioData || !this._usuarioData.token) {
      const usuarioSalvo = localStorage.getItem(this.currentModuleId);
      if(usuarioSalvo) {
        Object.assign(this._usuarioData, JSON.parse(usuarioSalvo));
      } else {
        this._usuarioData = new UsuarioModel();
      }
    }
    return this._usuarioData;
  }

  moduleAdminIsActive(): boolean {
    return this.currentModuleId == this.acompanhamentoAdmId; 
  }

  informacoesUsuario(): any {
    if(localStorage.getItem(this.currentModuleId)){
      return JSON.parse(localStorage.getItem(this.currentModuleId));
    }
    const usuario = new UsuarioModel();
    return usuario;
  }

  setUsuarioDataStorage(usuario: UsuarioModel): void {
    this._usuarioData = usuario;
    return localStorage.setItem(this._currentModuleId, JSON.stringify(usuario));
  }

  setUsuarioStoragePermanecendoMesmoToken(usuario: UsuarioModel): void {
    const tokenUsuarioData = this._usuarioData.token;
    usuario.token = tokenUsuarioData;
    this._usuarioData = usuario;
    return localStorage.setItem(this._currentModuleId, JSON.stringify(usuario));
  }

  clearStorage() {
    localStorage.removeItem(this._currentModuleId);
  }

  cleanAllData() {
    localStorage.removeItem(this._currentModuleId);
    this._usuarioData = null;
  }

  userIsLogged(): boolean {
    const usuario: UsuarioModel = this.usuarioData;

    if(!usuario || !usuario.token || usuario.token.trim().length == 0) {
      this.clearStorage();
      return false;
    }
    
    return true;
  }

  logout(): void {
    this.cleanAllData();
  }

  updateUser(user: UsuarioModel): void {
    /* const usuario: UsuarioModel = this.usuarioData;
    usuario.token = user.token; */
    this.setUsuarioDataStorage(user);
  }

  setModule(currentModule: Modules): void {
    if(Modules.Admin == currentModule) {
      this.currentModuleId = this.acompanhamentoAdmId;
    } else if(Modules.Cliente == currentModule) {
      this.currentModuleId = this.acompanhamentoClienteId;
    } else {
      this.currentModuleId = '';
    }
  }

  get currentModuleId(): string {
    if(!this._currentModuleId) {
      this.currentModuleId = window.location.href.includes('-admin') ? this.acompanhamentoAdmId : this.acompanhamentoClienteId;
    }
    return this._currentModuleId;
  }

  set currentModuleId(currentModuleId: string) {
    this._currentModuleId = currentModuleId;
  }

  userHasM4Profile(): boolean {
    return this._currentModuleId == this.acompanhamentoAdmId;
  }

  userHasM4AdministradorProfile(): boolean {
    return this.getUserPerfil() == Perfil.Administrador;
  }

  getUserPerfil(): string {
    const usuario: UsuarioModel = this.usuarioData;
    return usuario.perfil;
  }
  getCliente(): any {
    const usuario: UsuarioModel = this.usuarioData;
    return usuario.cliente;
  }
  // Permissoes de cliente devem ser tratadas em outro service
  userClientHasGoldProfile() {
    return this.getUserPerfil() == PerfilCliente.Gold;
  }
}