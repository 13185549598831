import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Perfil } from '../enums/perfil';
import { AutenticacaoService } from './autenticacao.service';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor(private router: Router, private autenticacaoService: AutenticacaoService) { }

  /**
   * TODO: Melhorar esse trecho de código. Sistema deverá identificar em qual modulo o usuário está e fazer o redirecionamento de acordo
   * 
   */
   navegarCampanhaHome(id: string): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/acompanhamento-admin/detalhes-campanha-instagram'],{ queryParams: { id } });
    } else {
      this.router.navigate(['/acompanhamento-cliente/detalhes-campanha-instagram'], { queryParams: { id } });
    }
  }

  navegarCampanhaHomeNewtab(id: string): void {

    if(this.autenticacaoService.userHasM4Profile()) {
      const url: string = '/#' + String(this.router.createUrlTree(['/acompanhamento-admin/detalhes-campanha-instagram'], { queryParams: { id } }));
      window.open(url, '_blank');
    } else {
      const url: string = '/#' + String(this.router.createUrlTree(['/acompanhamento-cliente/detalhes-campanha-instagram'], { queryParams: { id } }));
      window.open(url, '_blank');
    }
  }

  /**
   * TODO: Melhorar esse trecho de código. Sistema deverá identificar em qual modulo o usuário está e fazer o redirecionamento de acordo
   * 
   */
   navegarCampanhaYoutubeHome(id: string): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/acompanhamento-admin/detalhes-campanha-youtube'],{ queryParams: { id } });
    } else {
      this.router.navigate(['/acompanhamento-cliente/detalhes-campanha-youtube'], { queryParams: { id } });
    }
  }

  /**
   * TODO: Melhorar esse trecho de código. Sistema deverá identificar em qual modulo o usuário está e fazer o redirecionamento de acordo
   * 
   */
   navegarCampanhaTiktokHome(id: string): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/acompanhamento-admin/detalhes-campanha-tiktok'],{ queryParams: { id } });
    } else {
      this.router.navigate(['/acompanhamento-cliente/detalhes-campanha-tiktok'], { queryParams: { id } });
    }
  }

    /**
   * TODO: Melhorar esse trecho de código. Sistema deverá identificar em qual modulo o usuário está e fazer o redirecionamento de acordo
   * 
   */
     navegarCampanhaTwitchHome(id: string): void {
      if(this.autenticacaoService.userHasM4Profile()) {
        this.router.navigate(['/acompanhamento-admin/detalhes-campanha-twitch'],{ queryParams: { id } });
      } else {
        this.router.navigate(['/acompanhamento-cliente/detalhes-campanha-twitch'], { queryParams: { id } });
      }
    }

  campanhasList(): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/acompanhamento-admin/campanhas']);
    } else {
      this.router.navigate(['/acompanhamento-cliente/campanhas']);
    }
  }
  comunidadeList(): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/acompanhamento-admin/comunidade-campanhas']);
    } else {
      this.router.navigate(['/acompanhamento-cliente/comunidade-campanhas']);
    }
  }
  comunidadeAcompanhamento(id:string): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/acompanhamento-admin/comunidade-campanhas/acompanhamento-comunidade'],{ queryParams: { id }});
    } else {
      this.router.navigate(['/acompanhamento-cliente/comunidade-campanhas/acompanhamento-comunidade'],{ queryParams: { id }});
    }
  }

  navegarLogin(): void {
    if(this.autenticacaoService.userHasM4Profile()) {
      this.router.navigate(['/login-admin']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  navegarCadastroCliente(): void {
    this.router.navigate(['acompanhamento-admin/cadastro-cliente']);
  }
  
  navegarTelaInicialAdm() {
    if(this.autenticacaoService.getUserPerfil() == Perfil.Administrador || this.autenticacaoService.getUserPerfil() == Perfil.Coordenador || this.autenticacaoService.getUserPerfil() == Perfil.ConsultorCampanha) {
      this.campanhasList();
    } else {
      this.navegarCadastroCliente();
    }
  }
}
