import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoriasStorageService } from '../services/categorias-storage.service';
import { M4Service } from '../services/m4.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriaGuard implements CanActivate {

  constructor(private m4Service: M4Service, private categoriaStorage: CategoriasStorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(!this.categoriaStorage.categorias || this.categoriaStorage.categorias.length == 0) {
        this.m4Service.get(environment.serverUrl+'/'+environment.getCategorias).subscribe(res => this.categoriaStorage.categorias = res);
      }
    return true;
  }
}
