import { Injectable } from '@angular/core';
import { RecuperarSenhaModel } from '../models/usuario-model';

@Injectable({
  providedIn: 'root'
})
export class RecuperarSenhaStorageService {

  constructor() { }

  private _form:RecuperarSenhaModel;
  private _isAdmin:Boolean;
    
  get form():RecuperarSenhaModel{
    return this._form;
  }
  set form(form: RecuperarSenhaModel) {
    this._form = form;
  }

  get isAdmin():Boolean {
    return this._isAdmin;
  }
  set isAdmin(isAdmin: Boolean){
    this._isAdmin = isAdmin;
  }
}
