import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _show: boolean = false;
  private _msg: string;

  showLoading(show: boolean = true, msg: string = 'Salvando os dados... Por favor, aguarde!') {
    this._msg = msg;
    this._show = show;
  }
  get show() {
    return this._show;
  }
  get msg() {
    return this._msg;
  }

  isLoading(): boolean {
    return this._show;
  }
  constructor() { }
}
