import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { BsModalRef, ModalContainerComponent } from "ngx-bootstrap/modal";
import { DynamicModalStorageService } from "../../services/dynamic-modal-storage.service";

@Component({
  selector: "app-dynamic-modal",
  templateUrl: "./dynamic-modal.component.html",
  styleUrls: ["./dynamic-modal.component.scss"],
})
export class DynamicModalComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  @ViewChild(ModalContainerComponent) modal;

  title: string = this.dynamicModalStorageService.config.title;
  subtitle: string = this.dynamicModalStorageService.config.subtitle;

  private componentRef: ComponentRef<{}>;

  constructor(
    public bsModalRef: BsModalRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dynamicModalStorageService: DynamicModalStorageService
  ) {}

  ngOnInit() {
    const component = this.dynamicModalStorageService.component;
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.container.createComponent(factory);

    var body = document.getElementsByTagName("body")[0];
    if (!body.classList.contains("white-content")) {
      document
        .getElementsByTagName("modal-container")[0]
        .classList.add("modal-black");
    }

    if (this.dynamicModalStorageService.config.classModalContent) {
      document
        .getElementsByTagName("modal-container")[0]
        .classList.add(
          this.dynamicModalStorageService.config.classModalContent
        );
    }
  }
}
