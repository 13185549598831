import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AutenticacaoService } from "src/app/acompanhamento/services/autenticacao.service";
import { DynamicModalService } from "src/app/acompanhamento/services/dynamic-modal.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NavigatorService } from "src/app/acompanhamento/services/navigator.service";
import { PerfilUserFormComponent } from "src/app/acompanhamento/modulo-admin/perfil-user-form/perfil-user-form.component";
import { PerfilUserClientFormComponent } from "src/app/acompanhamento/modulo-cliente/perfil-user-client-form/perfil-user-client-form.component";
import { RouteInfo } from "src/app/acompanhamento/interfaces/route-info";
import { ROUTES_ADMIN } from "src/app/acompanhamento/modulo-admin/home-admin/side-bar-items";
import { ROUTES_CLIENTE } from "src/app/acompanhamento/modulo-cliente/side-bar-items";

var misc: any = {
  sidebar_mini_active: true
};
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit, OnDestroy {
  private listTitles: any[];
  location: Location;
  routes: RouteInfo[];

  private toggleButton: any;
  public isCollapsed = true;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public toastr: ToastrService,
    private autenticacaoService: AutenticacaoService,
    private dynamicModalService: DynamicModalService,
    public bsModalRef: BsModalRef,
    public bsModalRef1: BsModalRef,
    private navigatorService: NavigatorService
  ) {
    this.location = location;
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    var navbar = document.getElementsByClassName("navbar")[0];
    if (window.innerWidth < 993 && !this.isCollapsed) {
      navbar.classList.add("bg-white");
      navbar.classList.remove("navbar-transparent");
    } else {
      navbar.classList.remove("bg-white");
      navbar.classList.add("navbar-transparent");
    }
  };

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
      // this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add("sidebar-mini");
      // this.showSidebarMessage("Sidebar mini activated...");
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  showSidebarMessage(message) {
    this.toastr.show(
      '<span data-notify="icon" class="tim-icons icon-bell-55"></span>',
      message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }
  ngOnInit() {
    window.addEventListener("resize", this.updateColor);
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.subscribe(event => {
      this.sidebarClose();
    });
    if(this.autenticacaoService.userHasM4Profile()) {
      this.routes = ROUTES_ADMIN;
    } else {
      this.routes = ROUTES_CLIENTE;
    }
  }
  ngOnDestroy() {
    window.removeEventListener("resize", this.updateColor);
  }

  getTitle() {
    const url: string = window.location.href.split('?')[0];
    const routeI: RouteInfo = this.routes.find(r => url.endsWith(r.path))
    if(routeI) {
      return routeI.title;
    }
    return "";
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = <HTMLElement>(
      document.getElementsByTagName("body")[0]
    );
    const html = document.getElementsByTagName("html")[0];
    if (window.innerWidth < 991) {
      body.style.position = "fixed";
    }

    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");
    var $layer = document.createElement('div');
    $layer.setAttribute('id', 'bodyClick');


    if (html.getElementsByTagName('body')) {
      document.getElementsByTagName('body')[0].appendChild($layer);
    }
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    $layer.onclick = function () { //asign a function
      html.classList.remove('nav-open');
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
      const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

      if (window.innerWidth < 991) {
        setTimeout(function () {
          mainPanel.style.position = '';
        }, 500);
      }
    }.bind(this);

    html.classList.add('nav-open');
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    const body = <HTMLElement>(
      document.getElementsByTagName("body")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function () {
        body.style.position = "";
      }, 500);
    }
    var $layer: any = document.getElementById("bodyClick");
    if ($layer) {
      $layer.remove();

    }
    html.classList.remove("nav-open");
  }

  logout(): void {
    this.autenticacaoService.logout();
    this.navigatorService.navegarLogin();
  }

  abrirPerfil(): void {
    if (this.autenticacaoService.userHasM4Profile()) {
      this.bsModalRef = this.dynamicModalService.showModal(PerfilUserFormComponent, { title: "", subtitle: "Perfil Usuário M4", class: 'modal-lg', data: this.autenticacaoService.informacoesUsuario() });
      this.bsModalRef.content.onClose = () => {};
    } else {
      this.bsModalRef1 = this.dynamicModalService.showModal(PerfilUserClientFormComponent, { title: "", subtitle: "Perfil Usuário CLiente", class: 'modal-lg', data: this.autenticacaoService.informacoesUsuario() });
      this.bsModalRef1.content.onClose = () => {};
    }
  }
}
