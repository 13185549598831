import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Modules } from "src/app/acompanhamento/enums/modules-enum";
import { AutenticacaoService } from "src/app/acompanhamento/services/autenticacao.service";
import { LoginClienteService } from "src/app/acompanhamento/services/login-cliente.service";
import { MessageNotificationService } from "../../services/message-notification.service";
import { RecuperarSenhaStorageService } from '../recuperar-senha/service/recuperar-senha-storage.service';

@Component({
  selector: "app-login-cliente",
  templateUrl: "login-cliente.component.html"
})
export class LoginClienteComponent implements OnInit, OnDestroy {
  focus;
  focus1;

  constructor(private router: Router, private loginCliente: LoginClienteService, 
    private autenticacaoService: AutenticacaoService,
    public messageNotificationService: MessageNotificationService,
    private recuperarSenhaStorageService:RecuperarSenhaStorageService
    ) {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
    body.classList.remove("white-content");

  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
  }
  entrar(): void {
    let email: string = (<HTMLInputElement>document.getElementById('email')).value;
    const senha: string = (<HTMLInputElement>document.getElementById('pass')).value;

    if(email) {
      email = email.trim();
    }

    this.loginCliente.post({email, senha}).subscribe(
      res => {
        this.autenticacaoService.setModule(Modules.Cliente);
        this.autenticacaoService.updateUser(res);
        this.messageNotificationService.showNotification('Login realizado com sucesso');
        this.router.navigate(['acompanhamento-cliente/campanhas']);
      }, 
      err => {
        this.messageNotificationService.showNotification(err.error.descricaoErro);
      }
    );
  }
  abrirModalRecuperarSenha(){
    this.router.navigate(['recuperar-senha'])
    this.recuperarSenhaStorageService.isAdmin = false;
  }
}
