import { Perfil, PerfilCliente } from "../enums/perfil";
import { RouteInfo } from "../interfaces/route-info";


//Menu Items
export const ROUTES_CLIENTE: RouteInfo[] = [
	// {
	// 	path: "/acompanhamento-cliente",
	// 	title: "Dashboard",
	// 	type: "link",
	// 	icontype: "tim-icons icon-chart-pie-36",
	// 	rtlTitle: "لوحة القيادة"
	// },
	{
		path: "campanhas",
		title: "Minhas Campanhas",
		type: "link",
		icontype: "tim-icons icon-paper",
		rtlTitle: "لوحة القيادة"
	},
	{
		path: "busca-influenciadores",
		title: "Buscar Influenciadores",
		type: "link",
		icontype: "tim-icons icon-zoom-split",
		rtlTitle: "لوحة القيادة",
		perfis: [PerfilCliente.Gold]
	},
	{
		path: "comunidade-campanhas",
		title: "Comunidade",
		type: "link",
		icontype: "tim-icons icon-puzzle-10",
		rtlTitle: "لوحة القيادة",
	},
];