import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AtualizarUsuarioClienteModel } from '../../model/usuario-model';
import { DynamicModalStorageService } from '../../services/dynamic-modal-storage.service';
import { UsuarioService } from '../../services/usuario.service';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { MustMatch } from '../../utils/enum-utils';
import { AutenticacaoService } from '../../services/autenticacao.service';
import { NavigatorService } from '../../services/navigator.service';
import { MessageNotificationService } from '../../services/message-notification.service';
import { updateFormsValidity } from '../../utils/utils';

@Component({
  selector: 'app-perfil-user-client-form',
  templateUrl: './perfil-user-client-form.component.html',
  styleUrls: ['./perfil-user-client-form.component.scss']
})
export class PerfilUserClientFormComponent implements OnInit {

  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
  tabAtiva: string;

  form: FormGroup;
  formAlterarSenha: FormGroup;
  usuario: AtualizarUsuarioClienteModel = new AtualizarUsuarioClienteModel();

  alterarSenha: boolean = false;
  public register = false; 

  constructor(
    public bsModalRef: BsModalRef,
    private dynamicModalStorageService: DynamicModalStorageService,
    public messageNotificationService: MessageNotificationService, 
    private usuarioService: UsuarioService,
    private autenticacaoService: AutenticacaoService,
    private navigatorService: NavigatorService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.editarUserM4();
  }

  private buildForm(): void {
    this.form = new FormGroup({
      nome: new FormControl(this.usuario.nome, [Validators.required]),
      email: new FormControl(this.usuario.email, [Validators.required, Validators.email]),
      _id: new FormControl(this.usuario._id, [Validators.required]),
    });

    this.formAlterarSenha = new FormGroup({
      email: new FormControl(this.usuario.email, [Validators.required, Validators.email]),
      senha: new FormControl(this.usuario.senha, [Validators.required]),
      novaSenha: new FormControl(this.usuario.novaSenha, [Validators.required, Validators.minLength(6)]),
      confirmarSenha: new FormControl('', [Validators.required]),
      _id: new FormControl(this.usuario._id, [Validators.required]),
    },
    {
      validators: MustMatch("novaSenha", "confirmarSenha")
    });
  }

  private editarUserM4(): void {
    this.form.patchValue(this.dynamicModalStorageService.config.data);
    this.formAlterarSenha.get('_id').setValue(this.dynamicModalStorageService.config.data._id);
  }

  public gravarUsuarioPerfil(): void {

    if(this.alterarSenha){
      updateFormsValidity([this.formAlterarSenha]);

      if(!this.formAlterarSenha.valid) {
        this.messageNotificationService.showNotification('Por favor, preencha os campos obrigatórios antes de prosseguir!');
        return;
      }

      let formAlterarSenhaEnviar = JSON.parse(JSON.stringify(this.formAlterarSenha.value));
      delete formAlterarSenhaEnviar.confirmarSenha;
      
      this.usuarioService.postGravarAtualizarSenhaUsuarioCliente(formAlterarSenhaEnviar).subscribe(result => {
        this.messageNotificationService.showNotification('Senha atualizada com sucesso, faça seu login novamente!');
        this.logout();
        this.fecharModal();
      });

    }else{

      updateFormsValidity([this.form]);

      if(!this.form.valid) {
        this.messageNotificationService.showNotification('Por favor, preencha os campos obrigatórios antes de prosseguir!');
        return;
      }
      this.usuarioService.postGravarAtualizarUsuarioCliente(this.form.value).subscribe(result => {
        this.messageNotificationService.showNotification('Usuário gravado com sucesso!');
        this.autenticacaoService.setUsuarioStoragePermanecendoMesmoToken(result);
        this.fecharModal();
      });
    }
  }

  public fecharModal(): void {
    this.bsModalRef.content.onClose();
    this.bsModalRef.hide();
  }

  onSelect(data: TabDirective): void {
    this.tabAtiva = data.id;
    if(data.id == 'tabAlterarSenha'){
      this.alterarSenha = true;
    }else{
      this.alterarSenha = false;
    }
  }

  get registerF() {
    return this.form.controls;
  }

  get registerFormAlterarSenha() {
    return this.formAlterarSenha.controls;
  }

  logout(): void {
    this.autenticacaoService.logout();
    this.navigatorService.navegarLogin();
  }

}
