import { FormGroup } from "@angular/forms";
import { objetivos } from "../constants/objetivos";
import { CampanhaModel } from "../model/campanha-model";
import { CampanhaRedeSocialModel } from "../model/campanha-rede-social-model";

export function updateFormsValidity(forms: FormGroup[]): void {
    const formsArr: FormGroup[] = forms;
    formsArr.forEach((f: FormGroup) => {
      Object.keys(f.controls).forEach(key => {
        f.controls[key].markAsTouched();
        f.controls[key].markAsDirty();
        f.controls[key].updateValueAndValidity();
      });
    });
}

export function isCampanhaPerformanceGamificacao(campanha: CampanhaModel): boolean {
  return campanha.objetivos.includes(objetivos.PerformanceGamificacao.value);
}
export function isCampanhaRedeSocialPerformanceGamificacao(campanha: CampanhaRedeSocialModel): boolean {
  return campanha.objetivos.includes(objetivos.PerformanceGamificacao.value);
}
export function generateThumbnailBase64FromVideo(video, scaleFactor: number) {
  if(scaleFactor == null){
    scaleFactor = 1;
  }
  var w = video.videoWidth * scaleFactor;
  var h = video.videoHeight * scaleFactor;
  var canvas = document.createElement('canvas');
  canvas.width  = w;
  canvas.height = h;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, w, h);
  return canvas.toDataURL();
}

export function convertSecondsToTimeStringLocale(seconds: number): string {
  const totalDuracaoVideosStoriesDate: Date = new Date();
  totalDuracaoVideosStoriesDate.setHours(0,0,0,0);
  totalDuracaoVideosStoriesDate.setSeconds(seconds);

  return totalDuracaoVideosStoriesDate.toLocaleTimeString();
}